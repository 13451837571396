import * as React from 'react'
import { Helmet } from 'react-helmet-async'

export function Loggly() {
  return (
    <React.Fragment>
      <Helmet>
        <script
          type="text/javascript"
          src="https://cloudfront.loggly.com/js/loggly.tracker-latest.min.js"
          async
        />
        <script>{`
          window._LTracker = window._LTracker || [];
          _LTracker.push({
            'logglyKey': 'ac109de0-32b9-44f3-8f23-5d13246c128d',
            'sendConsoleErrors' : true,
            'tag': 'loggly-jslogger'
          });
        `}</script>
      </Helmet>
    </React.Fragment>
  )
}

export default Loggly
